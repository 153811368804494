/* Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital@0;1&display=swap");

/* Website reset */
* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
}

html {
   font-size: 62.5%;
   font-family: "Montserrat", sans-serif;
   scroll-behavior: smooth;
}

@media only screen and (min-width: 1800px) {
   html {
      font-size: 75%;
   }
}

body {
   min-height: 100vh;
}

::selection {
   background-color: slateblue;
   color: #fff;
}
